var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticStyle: { overflow: "visible" },
      attrs: { rounded: "xl", flat: "" }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "text-center fill-height",
          attrs: { "no-gutters": "", justify: "center", align: "end" }
        },
        [
          _c(
            "v-col",
            {
              staticStyle: { height: "80%", "overflow-y": "auto" },
              attrs: { cols: "12" }
            },
            [
              _c(
                "v-row",
                {
                  staticStyle: { height: "100%", "overflow-y": "auto" },
                  attrs: { align: "center", "no-gutters": "" }
                },
                [
                  _c("select-goal", {
                    class: !_vm.$vuetify.breakpoint.xs ? "px-3" : "",
                    attrs: { "focus-area": _vm.goal, "can-update-pain": "" },
                    on: {
                      onGoalSelected: _vm.onGoalSelected,
                      updatePainArea: function($event) {
                        return _vm.$emit("updatePainArea")
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "my-4", attrs: { cols: "10", sm: "6", lg: "3" } },
            [
              this.selectedGoal >= 0 && this.selectedGoal < 3
                ? _c(
                    "v-btn",
                    {
                      staticClass: "text-body-1",
                      attrs: { rounded: "", width: "100%", color: "primary" },
                      on: { click: _vm.confirm }
                    },
                    [_vm._v(_vm._s(_vm.$t("painAssessment.changeGoalButton")))]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }