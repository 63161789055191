<template>
  <v-card rounded="xl" style="overflow: visible" flat>
    <v-row
      no-gutters
      class="text-center fill-height"
      justify="center"
      align="end"
    >
      <v-col cols="12" style="height: 80%; overflow-y: auto"
        ><v-row align="center" no-gutters style="height: 100%; overflow-y: auto"
          ><select-goal
            :class="!$vuetify.breakpoint.xs ? 'px-3' : ''"
            @onGoalSelected="onGoalSelected"
            :focus-area="goal"
            can-update-pain
            @updatePainArea="$emit('updatePainArea')"
        /></v-row>
      </v-col>

      <v-col cols="10" sm="6" lg="3" class="my-4">
        <v-btn
          v-if="this.selectedGoal >= 0 && this.selectedGoal < 3"
          rounded
          class="text-body-1"
          width="100%"
          color="primary"
          @click="confirm"
          >{{ $t("painAssessment.changeGoalButton") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import SelectGoal from "@/components/wellness/SelectGoal";
import { FocusArea } from "@/services/deskassessment/focus-areas";
import { updateUserGoal } from "@/customApi";
import { EventBus } from "@/services/events/event-bus";

export default {
  name: "ChangeGoalDialog",
  emits: ["updatePainArea"],
  components: { SelectGoal },
  props: {
    userId: String,
    goal: Number
  },
  data() {
    return {
      selectedGoal: this.goal
    };
  },
  methods: {
    async confirm() {
      if (this.selectedGoal < 0 || this.selectedGoal > 2) {
        return;
      }
      try {
        let dto = {
          userId: this.userId,
          goal: FocusArea.fromIntValue(this.selectedGoal).stringValue,
          isDefault: false
        };
        await updateUserGoal(dto);
        EventBus.$emit("updatePainStats");
        if (
          this.selectedGoal === FocusArea.ReducePain.intValue &&
          this.goal !== FocusArea.ReducePain.intValue
        ) {
          // The user has now changed their goal to reduce pain, so open the pain dialog.
          this.$emit("updatePainArea");
        }
      } catch (ex) {
        this.$logger.captureException(ex);
      } finally {
        this.$emit("close");
      }
    },
    onGoalSelected(goal) {
      this.selectedGoal = goal;
    }
  }
};
</script>
